import React, {Component, Fragment} from 'react';
import {Icono} from "../../../Include/Icono/Icono";
import Button from "@material-ui/core/Button";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import LinearIndeterminate from "../../../Include/Spinners/LinearIndeterminate";
import PropTypes from "prop-types";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from "dropbox";
import {FieldsJs} from "../../../../settings/General/General";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";

class ArchivosExpedientes extends Component {
	
	viewDbx = (item) => {
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken});
		let path = item.archivo;
		console.log("ITEM::: ", item, path);
		
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
	};
	
	obtenerFormato = (text) => {
		if (FieldsJs.Field(text) === true) {
			let t = text.split('.');
			return t[t.length -1];
		}
	};
	
	
	render() {
		
		const {
			index,
			variable,
			texto,
			id_ahorro,
			id_cat_documento,
			item,
			fileSelect,
			getBase64,
			disabledSeleccionarCambiarArchivo,
			width,
			height,
			padding,
		} = this.props;
		
		const lectura = !(!item.archivo);
		
		return (
			<Fragment>
				
				<div className={'card-1 h-100'}>
					
					<div align={'center'} style={{padding: (padding || 15).toString() + 'px'}}>
						
						{item.loading ? (
							<Fragment>
								
								<div className={'h-140-px w-80-px vertical-inline'}>
									<div className={'w-100 v-center'}>
										<LinearIndeterminate/>
									</div>
								</div>
							
							</Fragment>
						) : (
							<Fragment>
								
								<div className={'padding-15-T padding-15-B'}>
									{Icono.get(item.formato || this.obtenerFormato(item.archivo), (width > 0 ? width.toString() + 'px' : '80px'), (height > 0 ? height.toString() + 'px' : '80px'))}
								</div>
								
								<Fragment>
									{!disabledSeleccionarCambiarArchivo ? (
										<Button size="small" color="primary"
										        onClick={() => fileSelect(variable + "_" + index)}>
											{lectura ? 'Cambiar' : 'Seleccionar'}
										</Button>
									) : ''}
									<input type="file" id={variable + "_" + index}
									       onChange={(e) => getBase64(e, variable, id_ahorro, id_cat_documento)}
									       className={'display-none'}
									/>
								</Fragment>
								
								{lectura ? (
									<Fragment>
										<ModalImage tipo={'add'} desing={this.props.desing || 1} item={item}/>
									</Fragment>
								) : ''}
							
							</Fragment>
						)}
						
						<h5 className={'margin-5'}>{texto}{item.is_required === 1 ? (
							<span className={'text-danger'}> (Requerido)</span>) : ''}</h5>
					
					</div>
				
				</div>
			
			</Fragment>
		)
	}
}

ArchivosExpedientes.propTypes = {
	index: PropTypes.number.isRequired,
	variable: PropTypes.string.isRequired,
	texto: PropTypes.string.isRequired,
	id_ahorro: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	id_cat_documento: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	item: PropTypes.object.isRequired,
	fileSelect: PropTypes.func.isRequired,
	getBase64: PropTypes.func.isRequired,
	disabledSeleccionarCambiarArchivo: PropTypes.bool.isRequired,
	width: PropTypes.number,
	height: PropTypes.number,
	desing: PropTypes.oneOf([
		1,
		2,
		3,
	]),
	padding: PropTypes.number
};

export default ArchivosExpedientes;
