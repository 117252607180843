import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const AcuerdosService = {
	Listar: (filtro, paginacion) => {
		let fecha = filtro.fecha ? DateFormat.FormatSql(filtro.fecha) : null;
		let params = {
			filtro: {
				acuerdos: filtro.acuerdos || null,
				alta_baja_socio: filtro.alta_baja_socio || null,
				fecha: fecha || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acuerdos_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acuerdos_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_acuerdos: null,
			codigo: form.codigo || null,
			id_cat_grupo: form.id_cat_grupo || null,
			acuerdos: form.acuerdos || null,
			alta_baja_socio: form.alta_baja_socio || null,
			observaciones: form.observaciones || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acuerdos_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_acuerdos: form.id_acuerdos || null,
			codigo: form.codigo || null,
			id_cat_grupo: form.id_cat_grupo || null,
			acuerdos: form.acuerdos || null,
			alta_baja_socio: form.alta_baja_socio || null,
			observaciones: form.observaciones || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acuerdos_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_acuerdos) => {
		let params = {
			id_acuerdos: id_acuerdos
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Acuerdos_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
