import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, generarRandomCode} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	HdrWeakOutlined,
	TurnedInNotOutlined,
	AttachMoney, HdrStrongOutlined
} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {PrestamoService} from "../../../../services/PrestamoService/PrestamoService";
import moment from "moment";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";

class ModalPrestamo extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_prestamo: (props.item || '').id_prestamo,
			nombre: '',
			id_cat_grupo: '',
			codigo: '',
			id_vigente: '',
			activo: '',
			id_cat_sexo: '',
			id_cat_municipio: '',
			id_cat_estado: '',
			id_cat_localidad: '',
			intereses: '',
			observaciones: '',
			monto: '',
			fecha: null,
			fecha_vencimiento: null,
			
			lista_usuario: [],
			lista_sexo: [],
			lista_estado: [],
			lista_municipio: [],
			lista_localidad: [],
			lista_grupo: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			
			if (this.state.id_cat_estado > 0)
				this.ListMunicipio(null, this.state.id_cat_estado);
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListUsuario = () => {
		return new Promise((resolve, reject) => {
			CatService.ListUsuario().then((response) => {
				this.setState({
					lista_usuario: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	
	ListGrupo = () => {
		return new Promise((resolve, reject) => {
			CatService.ListGrupo().then((response) => {
				this.setState({
					lista_grupo: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	ListSexo = () => {
		return new Promise((resolve, reject) => {
			CatService.ListSexo().then((response) => {
				this.setState({
					lista_sexo: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	ListEstado = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEstado().then((response) => {
				this.setState({
					lista_estado: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListMunicipio = (id_cat_estado) => {
		return new Promise((resolve, reject) => {
			CatService.ListMunicipio(id_cat_estado).then((response) => {
				this.setState({
					lista_municipio: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListLocalidad = (id_cat_municipio) => {
		return new Promise((resolve, reject) => {
			CatService.ListLocalidad(id_cat_municipio).then((response) => {
				this.setState({
					lista_localidad: response.data
				});
				resolve(response);
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_prestamo > 0) {
			PrestamoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			PrestamoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		this.ListUsuario();
		this.ListSexo();
		this.ListEstado();
		this.ListGrupo();
		
		this.setState({
			id_prestamo: (item || '').id_prestamo,
			nombre: '',
			id_cat_grupo: '',
			codigo: generarRandomCode(30),
			id_vigente: '',
			id_cat_sexo: '',
			id_cat_municipio: '',
			id_cat_estado: '',
			id_cat_localidad: '',
			intereses: '',
			observaciones: '',
			monto: '',
			fecha: null,
			fecha_vencimiento: null,
			activo: true,
			
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.ListGrupo().then((response) => {
			this.setState({
				id_cat_grupo: item.id_cat_grupo || '',
			});
		});
		this.ListSexo().then((response) => {
			this.setState({
				id_cat_sexo: item.id_cat_sexo || '',
			});
		});
		this.ListEstado().then((response) => {
			this.setState({
				lista_estado: response.data,
				id_cat_estado: item.id_cat_estado || '',
			});
		});
		this.ListMunicipio(item.id_cat_estado).then((response) => {
			this.setState({
				lista_municipio: response.data,
				id_cat_municipio: item.id_cat_municipio || '',
			});
		});
		this.ListLocalidad(item.id_cat_municipio).then((response) => {
			this.setState({
				lista_localidad: response.data,
				id_cat_localidad: item.id_cat_localidad || '',
			});
		});
		
		this.setState({
			id_prestamo: (item || '').id_prestamo,
			nombre: item.nombre || '',
			codigo: item.codigo || generarRandomCode(30),
			edad: item.edad || '',
			comunidad: item.comunidad || '',
			intereses: item.intereses || '',
			id_vigente: item.id_vigente || '',
			observaciones: item.observaciones || '',
			monto: item.monto || '',
			fecha: item.fecha ? moment(item.fecha) : null,
			fecha_vencimiento: item.fecha_vencimiento ? moment(item.fecha_vencimiento) : null,
			activo: (Number(item.activo) === 1)
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		/*this.ListUsuario().then((response) => {
			this.setState({
				nombre: item.nombre || '',
			});
		});*/
		this.ListGrupo().then((response) => {
			this.setState({
				id_cat_grupo: item.id_cat_grupo || '',
			});
		});
		this.ListSexo().then((response) => {
			this.setState({
				id_cat_sexo: item.id_cat_sexo || '',
			});
		});
		this.ListEstado().then((response) => {
			this.setState({
				id_cat_estado: item.id_cat_estado || '',
			});
		});
		this.ListMunicipio(item.id_cat_estado).then((response) => {
			this.setState({
				id_cat_municipio: item.id_cat_municipio || '',
			});
		});
		this.ListLocalidad(item.id_cat_municipio).then((response) => {
			this.setState({
				id_cat_localidad: item.id_cat_localidad || '',
			});
		});
		
		this.setState({
			id_prestamo: (item || '').id_prestamo,
			nombre: item.nombre || '',
			codigo: item.codigo || generarRandomCode(30),
			edad: item.edad || '',
			comunidad: item.comunidad || '',
			id_vigente: item.id_vigente || '',
			intereses: item.intereses || '',
			observaciones: item.observaciones || '',
			monto: item.monto || '',
			fecha: item.fecha ? moment(item.fecha) : null,
			fecha_vencimiento: item.fecha_vencimiento ? moment(item.fecha_vencimiento) : null,
			activo: (Number(item.activo) === 1)
		});
		this.open();
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_prestamo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Prestamo'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="nombre"
											onChange={this.handleChange}
											label="Nombre completo"
											autoComplete={'off'}
											value={this.state.nombre}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Grupo</InputLabel>
											<Select
												native
												value={this.state.id_cat_grupo}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_grupo"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_grupo.map(option => (
													<option key={option.id_cat_grupo}
													        value={option.id_cat_grupo}>
														{option.cat_grupo}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="edad"
											onChange={this.handleChange}
											inputProps={{maxLength: 10}}
											onKeyPress={EnteroSolo}
											label="Edad"
											autoComplete={'off'}
											value={this.state.edad}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Sexo</InputLabel>
											<Select
												native
												value={this.state.id_cat_sexo}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_sexo"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_sexo.map(option => (
													<option key={option.id_cat_sexo}
													        value={option.id_cat_sexo}>
														{option.sexo}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="intereses"
											onChange={this.handleChange}
											label="Intereses pagados"
											autoComplete={'off'}
											value={this.state.intereses}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="id_vigente"
											onChange={this.handleChange}
											label="Vigente"
											autoComplete={'off'}
											value={this.state.id_vigente}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Estado</InputLabel>
											<Select
												native
												value={this.state.id_cat_estado}
												onChange={(e) => {
													this.handleChange(e);
													this.ListMunicipio(e.target.value);
												}}
												name="id_cat_estado"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_estado.map(option => (
													<option key={option.id_cat_estado}
													        value={option.id_cat_estado}>
														{option.estado}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Municipio</InputLabel>
											<Select
												native
												value={this.state.id_cat_municipio}
												onChange={(e) => {
													this.handleChange(e);
													this.ListLocalidad(e.target.value);
												}}
												name="id_cat_municipio"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_municipio.map(option => (
													<option key={option.id_cat_municipio}
													        value={option.id_cat_municipio}>
														{option.municipio}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Localidad</InputLabel>
											<Select
												native
												value={this.state.id_cat_localidad}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_localidad"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_localidad.map(option => (
													<option key={option.id_cat_localidad}
													        value={option.id_cat_localidad}>
														{option.localidad}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="monto"
											onChange={this.handleChange}
											inputProps={{maxLength: 10}}
											onKeyPress={EnteroSolo}
											label="Monto"
											autoComplete={'off'}
											value={this.state.monto}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha',
									}}
									label="Fecha"
									value={this.state.fecha}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.props.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de Vencimiento',
									}}
									label="Fecha de Vencimiento"
									value={this.state.fecha_vencimiento}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_vencimiento');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.props.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											rows={3}
											multiline
											name="observaciones"
											onChange={this.handleChange}
											label="Observaciones o incidencias"
											autoComplete={'off'}
											value={this.state.observaciones}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_prestamo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPrestamo.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPrestamo;
