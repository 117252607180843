import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import './Home.css';
import logo_svg from '../../../assets/img/logo.png';
import ListItem from "@material-ui/core/ListItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import EstiloBotonAccesoDirectoInicio from "../../Include/MiniComponents/EstiloBotonAccesoDirectoInicio";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import $State from "../../../settings/$State/$State";
import HomeResponsive from "./Includes/responsive";
import {$cError, $cInfo, $cSuccess, FieldsJs} from "../../../settings/General/General";
import PusherWatchChannelEvent, {PusherSubscribe, PusherUnSubscribe} from "../../../settings/Pusher/Pusher";
import icon_a from "../../../assets/img/icons/ahorro.svg";
import icon_i from "../../../assets/img/icons/cafe.svg";
import icon_f from "../../../assets/img/icons/pollo.svg";
import icon_c from "../../../assets/img/icons/miel.png";
import icon_n from "../../../assets/img/icons/lineas-de-texto.svg";
import {BarraProgreso} from "../../Include/MiniComponents/BarraProgreso";
import {Chart} from "react-google-charts";

const ItemOrden = ({item}) => {
	
	const textoClaseIcono = "bg-img-contain-x-center-y-center h-15-px w-15-px cursor-pointer margin-2-R margin-2-L";
	
	const bg_i = {
		backgroundImage: `url(${icon_i})`
	};
	
	const bg_f = {
		backgroundImage: `url(${icon_f})`
	};
	
	const bg_c = {
		backgroundImage: `url(${icon_c})`
	};
	
	const bg_n = {
		backgroundImage: `url(${icon_n})`
	};
	
	let total_partidas = item.venta_producto.length;
	
	let total_productos_en_partidas = 0;
	
	for (let i = 0; i < item.venta_producto.length; i++) {
		let info = item.venta_producto[i];
		total_productos_en_partidas += Number(info.venta_cantidad);
	}
	
	return (
		<Fragment>
			<ListItem button style={{padding: 0, width: '100%', height: '100%'}}>
				
				<Card style={{width: '100%', height: '100%'}}>
					
					<CardContent style={{padding: '12px'}}>
						
						<Typography variant="h5" component="h2" align={'center'} style={{fontSize: '18px'}}>
							{item.numero_pedido}
						</Typography>
						
						<Typography color="textSecondary" gutterBottom style={{fontSize: '14px', lineHeight: '14px'}}>
							{DateFormat.FechaFormatString(item.fecha_entrega)}
						</Typography>
						
						<div className={'row-flex'}>
							<div className="w-100-34-px vertical-inline">
								<div className={'v-center w-100'} align={'left'}>
									<Typography component={'div'} style={{marginBottom: '5px', marginTop: '5px'}}>
										<BarraProgreso progreso={item.progreso || 0} labelText={''}/>
									</Typography>
									<Typography component={'div'} style={{marginBottom: '5px', marginTop: '5px'}}>
										<BarraProgreso progreso={(item.progreso_pagado || 0)} labelText={'Pagado'}
										               color={'#3F51B5'}/>
									</Typography>
								</div>
							</div>
							<div className="w-34-px vertical-inline">
								<div className={'v-center w-100'} align={'right'}>
									{item.cotizacion === 1 ? (
										<Tooltip TransitionComponent={Zoom} placement={"left"}
										         title={'Cotización: ' + item.numero_cotizacion}>
											<div style={bg_c} className={textoClaseIcono}/>
										</Tooltip>
									) : ''}
									{item.interno === 1 ? (
										<div style={bg_i} className={textoClaseIcono}/>
									) : ''}
									{item.nota_sencilla === 1 ? (
										<div style={bg_n} className={textoClaseIcono}/>
									) : ''}
									{item.factura === 1 ? (
										<div style={bg_f} className={textoClaseIcono}/>
									) : ''}
								</div>
							</div>
						</div>
						
						<Typography color="textSecondary" gutterBottom style={{fontSize: '12px', lineHeight: '12px'}}>
							{total_partidas || 0} partidas
							/ {Number(FieldsJs.Currency(total_productos_en_partidas)) || 0} productos
						</Typography>
					
					</CardContent>
				
				</Card>
			
			</ListItem>
		
		</Fragment>
	)
};

const ItemGrafica = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	grafica.push([state.venta_en_produccion_texto, state.venta_en_produccion.length]);
	grafica.push([state.venta_en_almacen_texto, state.venta_en_almacen.length]);
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'270px'}
						chartType="PieChart"
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={grafica}
						options={{
							title: '',
							legend: {
								alignment: 'center',
								position: 'bottom',
								textStyle: {
									color: 'black',
									fontSize: 10,
								},
							},
							pieHole: 0,
							animation: {
								duration: 1000,
								easing: 'out',
								startup: true,
							},
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

class Home extends Component {
	
	state = {}
	
	constructor(props) {
		super(props);
	
		this.state = {
			settings: {},
			
			venta_creado_disenador_asignado_texto: 'Órdenes nuevas / Enviado al diseñador',
			venta_en_almacen_texto: 'En almacén',
			venta_en_produccion_texto: 'En producción',
			
			venta_status_proceso: [],
			venta: [],
			venta_creado_disenador_asignado: [],
			venta_en_produccion: [],
			venta_en_almacen: [],
			
			loadding: false
		};
		
		this.InitComponent(true);
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	go = (ir) => {
		switch (ir) {
			case 1:
				$State.go(this.props, "ahorro", {});
				break;
			case 2:
				$State.go(this.props, "grupocafe", {});
				break;
			case 3:
				$State.go(this.props, "grupohuevopollo", {});
				break;
			case 4:
				$State.go(this.props, "grupomiel", {});
				break;
			default: break;
			
		}
	};
	
	updateDimensions = () => {
		HomeResponsive.get().then(settings => {
			this.setState({
				settings: settings
			});
		});
	};
	
	componentDidMount() {
		$cSuccess("componentDidMount");
		PusherSubscribe(null, info => {
			$cSuccess(info);
			this.InitPusher();
		});
		this.updateDimensions();
		window.addEventListener('resize', () => {
			this.updateDimensions();
		});
	}
	
	componentWillUnmount() {
		$cInfo("componentWillUnmount");
		PusherUnSubscribe(null, info => {
			$cInfo(info);
		});
		window.removeEventListener('resize', () => {
			this.updateDimensions();
		});
	}
	
	InitPusher = () => {
		PusherWatchChannelEvent(null, (data) => {
			$cError(JSON.stringify(data));
			FieldsJs.TimePromise(1000, () => this.InitComponent(false), () => console.warn('Tiempo cancelado por FieldsJs.TimePromise para actualizar los datos de la vista.'))
		});
	};
	
	InitComponent = (spinner) => {
		this.setState({loadding: true});
	};
	
	
	render() {
		/*const bg_logo_svg = {
			// backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px auto',
			// opacity: '0.2',
		};*/
		
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center 50%',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px auto',
		};
		
		let settings = this.state.settings;
		
		
		return (
			<Fragment>
			
			<div className='Home ContaineViewComponet bg-logo-svg' style={bg_logo_svg}>
				<div className={'w-auto vertical-inline'} style={{backgroundColor: "rgba(248, 248, 255, 0.0)",height: 'calc(100vh - 250px)'}}>
					<div className={'w-100 v-end top-actions'}>

						<Grid container spacing={settings.spacing || 10} direction={"row"} alignItems={"center"} alignContent={"center"}>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={'center'}></Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'} style={{marginBottom: 35}}>
								<EstiloBotonAccesoDirectoInicio
									debug={false}
									widthImg={settings.widthImg}
									marginImg={settings.marginImg}
									widthContent={settings.widthContent}
									heightContent={settings.heightContent}
									heightText={settings.heightText}
									backgroundContent={null}
									backgroundText={'#343434'}
									label={(
										<h4 className={'acceso-directo-label'} style={{color: "white"}}>CAJA DE AHORRO</h4>
									)}
									iconLeft={icon_a}
									onClick={() => this.go(1)}
								/>
							</Grid>
						
						</Grid>
						
						<Grid container spacing={settings.spacing || 10} direction={"row"} alignItems={"center"} alignContent={"center"}>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
								<EstiloBotonAccesoDirectoInicio
									debug={false}
									widthImg={settings.widthImg}
									marginImg={settings.marginImg}
									widthContent={settings.widthContent}
									heightContent={settings.heightContent}
									heightText={settings.heightText}
									backgroundContent={null}
									backgroundText={'#343434'}
									label={(
										<h4 className={'acceso-directo-label'} style={{color: "white"}}>CAFÉ</h4>
									)}
									iconRight={icon_i}
									onClick={() => this.go(2)}
								/>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
								<EstiloBotonAccesoDirectoInicio
									debug={false}
									widthImg={settings.widthImg}
									marginImg={settings.marginImg}
									widthContent={settings.widthContent}
									heightContent={settings.heightContent}
									heightText={settings.heightText}
									backgroundContent={null}
									backgroundText={'#343434'}
									label={(
										<h4 className={'acceso-directo-label'} style={{color: "white"}}>HUEVO Y POLLO</h4>
									)}
									iconRight={icon_f}
									onClick={() => this.go(3)}
								/>
							</Grid>
						
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
								<EstiloBotonAccesoDirectoInicio
									debug={false}
									widthImg={settings.widthImg}
									marginImg={settings.marginImg}
									widthContent={settings.widthContent}
									heightContent={settings.heightContent}
									heightText={settings.heightText}
									backgroundContent={null}
									backgroundText={'#343434'}
									label={(
										<h4 className={'acceso-directo-label'} style={{color: "white"}}>MIEL</h4>
									)}
									iconRight={icon_c}
									onClick={() => this.go(4)}
								/>
							</Grid>
						
						</Grid>
					</div>
				</div>
			</div>
			</Fragment>
		);
	}
}

Home.propTypes = {};

export default IntegrationNotistack(Home);
