import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs, generarRandomCode} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {AlbumService} from "../../../../services/AlbumService/AlbumService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import {DropzoneArea} from 'material-ui-dropzone';
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {CONFIG} from "../../../../settings/Config/Config";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import {HdrWeakOutlined, TurnedInNotOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

class ModalAlbum extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_album: (props.item || '').id_album,
			nombre: '',
			codigo: '',
			id_cat_grupo: ''
			,archivo_base64: '',
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
			files: [],
			fecha: null,
			activo: '',
			
			lista_grupo: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			
			if (this.state.id_cat_estado > 0)
				this.ListMunicipio(null, this.state.id_cat_estado);
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	ListGrupo = () => {
		return new Promise((resolve, reject) => {
			CatService.ListGrupo().then((response) => {
				this.setState({
					lista_grupo: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	save = () => {
		try {
			
			if (!FieldsJs.Field(this.state.fecha)) {
				throw Object({
					status: false, mensaje: 'Campo fecha es requerido.'
				})
			}
			if (this.state.files.length <= 0) {
				throw Object({
					status: false, mensaje: 'La imagen es requerida.'
				})
			}
			this.darformato(this.state.files[0]).then(() => {
				if (this.state.id_album > 0) {
					AlbumService.Modificar(this.state).then((response) => {
						this.close();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				} else {
					AlbumService.Agregar(this.state).then((response) => {
						this.close();
						this.props.RefreshList(response.data);
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	add = () => {
		const {item} = this.props;
		
		this.ListGrupo();
		
		this.setState({
			id_album: (item || '').id_album,
			id_cat_grupo: '',
			archivo_base64: '',
			codigo: generarRandomCode(30),
			archivo_base64Tipo: '',
			archivo: '',
			archivo_formato: '',
			archivo_nombre: '',
			nombre:  '',
			files: [],
			fecha: null,
			activo: true,
		
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.ListGrupo().then((response) => {
			this.setState({
				id_cat_grupo: item.id_cat_grupo || '',
			});
		});
		
		this.setState({
			id_album: (item || '').id_album,
			archivo: item.archivo || '',
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo_formato: '',
			archivo_nombre: '',
			nombre:  item.nombre || '',
			codigo:  item.codigo || '',
			files: [],
			fecha: item.fecha ? moment(item.fecha) : null,
			activo: (Number(item.activo) === 1)
		});
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.ListGrupo().then((response) => {
			this.setState({
				id_cat_grupo: item.id_cat_grupo || '',
			});
		});
		
		this.setState({
			id_album: (item || '').id_album,
			archivo: item.archivo || '',
			archivo_base64: '',
			archivo_base64Tipo: '',
			archivo_formato: '',
			archivo_nombre: '',
			nombre:  item.nombre || '',
			codigo:  item.codigo || '',
			files: [],
			fecha: item.fecha ? moment(item.fecha) : null,
			activo: (Number(item.activo) === 1)
		});
		
		this.open();
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
		
	};
	
	darformato = (file) => {
		return new Promise((resolve, reject) => {
			let formatos = ["image/jpeg", "image/png", "image/gif"];
			
			let fil = {files: [file]};
			FileBase64.Base64(fil, formatos, this.props.MegaByte || null, true).then((response) => {
				//this.props.onChange(response);
				this.handleChange({
					target: {
						name: 'archivo_base64',
						value: response.base64
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_base64Tipo',
						value: response.base64Tipo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo',
						value: response.archivo
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_formato',
						value: response.formato
					}
				});
				this.handleChange({
					target: {
						name: 'archivo_nombre',
						value: response.name
					}
				});
				resolve(true);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
				reject();
			});
		});
	}
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_album}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Album'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth required>
											<InputLabel>Grupo</InputLabel>
											<Select
												native
												value={this.state.id_cat_grupo}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_grupo"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.lista_grupo.map(option => (
													<option key={option.id_cat_grupo}
													        value={option.id_cat_grupo}>
														{option.cat_grupo}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<TurnedInNotOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="nombre"
											onChange={this.handleChange}
											label="Nombre del taller / sesión ahorro"
											autoComplete={'off'}
											value={this.state.nombre}
											required
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha',
									}}
									label="Fecha"
									value={this.state.fecha}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.props.tipo === 'view'}
								/>
							</Grid>
							
							{this.state.archivo ? (
							<Grid item className={'text-center'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<div className={'vertical-inline'}>
									<div className={'vertical-inline b-r-20 margin-5 margin-0-L'}>
										<img className={'v-center'} src={CONFIG.src + this.state.archivo} alt="add" width={100} height={100} style={{opacity: 0.7, marginRight: '5px'}}/>
										{this.props.tipo === 'edit' ? (<div className={'vertical-inline v-end b-r-20 margin-5'}>
											<div className={'padding-3 v-end text-white'}>
												{ this.props.tipo === 'view' ? null : (
													<DeleteOutlined className={'px-16 text-white'}/>
												)}
											</div>
										</div>) : null}
									</div>
								</div>
							</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100'}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										filesLimit={3}
										dropzoneText={'Arrastre y suelte la imagen aquí o haga clic'}
										///acceptedFiles={['image/jpeg, \'image/png\', image/gif']}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
								</div>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_album}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAlbum.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalAlbum;
