import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const InteresesService = {
	Listar: (filtro, paginacion) => {
		let fecha = filtro.fecha ? DateFormat.FormatSql(filtro.fecha) : null;
		let params = {
			filtro: {
				monto: filtro.monto || null,
				id_cat_grupo: filtro.id_cat_grupo || null,
				fecha: fecha || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Intereses_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Intereses_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_intereses: null,
			codigo: form.codigo || null,
			id_cat_grupo: form.id_cat_grupo || null,
			id_cat_estado: form.id_cat_estado || null,
			id_cat_municipio: form.id_cat_municipio || null,
			id_cat_localidad: form.id_cat_localidad || null,
			monto: form.monto || null,
			monto_acumulado: form.monto_acumulado || null,
			monto_socio: form.monto_socio || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Intereses_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_intereses: form.id_intereses || null,
			codigo: form.codigo || null,
			id_cat_grupo: form.id_cat_grupo || null,
			id_cat_estado: form.id_cat_estado || null,
			id_cat_municipio: form.id_cat_municipio || null,
			id_cat_localidad: form.id_cat_localidad || null,
			monto: form.monto || null,
			monto_acumulado: form.monto_acumulado || null,
			monto_socio: form.monto_socio || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Intereses_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_intereses) => {
		let params = {
			id_intereses: id_intereses
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Intereses_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
