import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {AhorroService} from "../../../../services/AhorroService/AhorroService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import moment from "moment";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import ArchivosExpedientes from "./ArchivosExpedientes";
import Button from "@material-ui/core/Button";

class ModalAhorro extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_ahorro: (props.item || '').id_ahorro,
			
			lista_documento: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
			
			if (this.state.id_cat_estado > 0)
				this.ListMunicipio(null, this.state.id_cat_estado);
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_ahorro > 0) {
			AhorroService.DocumentoModificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			AhorroService.DocumentoAgregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	ListDocumento = () => {
		return new Promise((resolve, reject) => {
			CatService.ListDocumento().then((response) => {
				this.setState({
					lista_documento: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	add = () => {
		const {item} = this.props;
		this.ListDocumento();
		this.setState({
			id_ahorro: (item || '').id_ahorro,
			id_usuario: '',
			edad: '',
			id_cat_sexo: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			id_cat_localidad: '',
			comunidad: '',
			grupo: '',
			monto: '',
			retirado: '',
			total: '',
			fecha: null,
			activo: true,
		
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.setState({
			id_ahorro: (item || '').id_ahorro,
			edad: item.edad || '',
			comunidad: item.comunidad || '',
			monto: item.monto || '',
			retirado: item.retirado || '',
			total: item.total || '',
			fecha: item.fecha ? moment(item.fecha) : null,
			activo: (Number(item.activo) === 1)
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.setState({
			id_ahorro: (item || '').id_ahorro,
			edad: item.edad || '',
			comunidad: item.comunidad || '',
			monto: item.monto || '',
			retirado: item.retirado || '',
			total: item.total || '',
			fecha: item.fecha ? moment(item.fecha) : null,
			activo: (Number(item.activo) === 1)
		});
		this.open();
	};
	
	fileSelect = (id) => {
		document.getElementById(id).value = '';
		document.getElementById(id).click();
	};
	
	getBase64 = (e, id, id_ahorro, id_cat_documento) => {
		let formatos = [".csv", "text/csv", "image/jpeg", "image/png", "application/pdf", "application/xlsx", "application/docx", "text/txt", "image/svg"];
		let files = e.target.files[0];
		
		console.log("FILES:::: ", e, id, id_ahorro, id_cat_documento, files);
		
		FileBase64.Base64(e.target, formatos, '', true).then((response) => {
			switch (id) {
				case 'archivo_integracion_expediente':
					
					let item = {
						id_cliente: this.state.id_cliente,
						id_ficha_tecnica: this.state.id_ficha_tecnica,
						id_cat_documento: id_cat_documento,
						id_ahorro: id_ahorro,
						archivo: response.archivo,
						formato: response.formato,
						file: files
					};
					
					
					console.log("GUARDAREXPEDIENTE::: ", this.state.ficha_tecnica_archivo_integracion_expediente, item);
					
					this.guardarExpedienteDbx(item, id_cat_documento).then(item => {
						this.active_inactive_spinner(true, id_ahorro, id_cat_documento);
						
						/*FichaTecnicaService.GuardarDatoIntegracionExpedienteXId(item).then((res) => {
							let arr_temp = this.state.ficha_tecnica_archivo_integracion_expediente;
							if (id_ahorro || id_cat_documento) {
								let uno = false;
								let dos = false;
								for (let i = 0; i < arr_temp.length; i++) {
									uno = Number(arr_temp[i].id_ahorro) === Number(id_ahorro) && Number(id_ahorro) > 0;
									dos = Number(arr_temp[i].id_cat_documento) === Number(id_cat_documento) && Number(id_cat_documento) > 0;
									if (uno && dos) {
										arr_temp[i].loading = false;
										arr_temp[i].base64 = response.base64;
										arr_temp[i].formato = res.data.formato || response.formato;
										arr_temp[i].archivo = res.data.archivo || response.archivo;
										arr_temp[i].id_ahorro = res.data.id_ahorro || null;
										arr_temp[i].id_cat_documento = res.data.id_cat_documento || null;
									} else if (uno && !dos) {
										arr_temp[i].loading = false;
										arr_temp[i].base64 = response.base64;
										arr_temp[i].formato = res.data.formato || response.formato;
										arr_temp[i].archivo = res.data.archivo || response.archivo;
										arr_temp[i].id_ahorro = res.data.id_ahorro || null;
										arr_temp[i].id_cat_documento = res.data.id_cat_documento || null;
									} else if (!uno && dos) {
										arr_temp[i].loading = false;
										arr_temp[i].base64 = response.base64;
										arr_temp[i].formato = res.data.formato || response.formato;
										arr_temp[i].archivo = res.data.archivo || response.archivo;
										arr_temp[i].id_ahorro = res.data.id_ahorro || null;
										arr_temp[i].id_cat_documento = res.data.id_cat_documento || null;
									}
								}
							} else {
								arr_temp.push({
									loading: false,
									base64: response.base64,
									formato: res.data.formato || response.formato,
									archivo: res.data.archivo || response.archivo,
									id_ahorro: res.data.id_ahorro || null,
									id_cat_documento: res.data.id_cat_documento || null,
								});
							}
							this.setState({
								ficha_tecnica_archivo_integracion_expediente: arr_temp
							});
						}).catch((error) => {
							
							this.active_inactive_spinner(false, id_ahorro, id_cat_documento);
							
							this.showSnackBars('error', error.mensaje);
							
						});*/
					}).catch((error) => {
						
						this.active_inactive_spinner(false, id_ahorro, id_cat_documento);
						
						this.showSnackBars('error', error.mensaje);
						
					});
					
					break;
				case 'constancia_de_situacion_fiscal':
				case 'comprobante_de_domicilio':
				case 'acta_constitutiva_de_la_cliente':
				case 'poder_del_representante_legal':
				case 'identificacion_del_repesentante_legal':
				case 'archivo_fiscal':
					this.setState({
						[id + '_base64']: response.base64,
						[id + '_formato']: response.formato,
						[id]: response.archivo
					});
					break;
				default:
			}
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	agregar_ficha_tecnica_cantidad_empleado_banco = () => {
		
		try {
			
			let arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_banco);
			let front_id_esquema_banco = this.state.front_id_esquema_banco;
			let front_nombre_esquema = this.state.front_nombre_esquema;
			let front_nombre_banco = this.state.front_nombre_banco;
			let front_id_banco = this.state.front_id_banco;
			let front_cantidad_empleado = this.state.front_cantidad_empleado;
			
			
			if (!front_id_esquema_banco) {
				throw Object({
					status: false,
					mensaje: "Selecciona un esquema para agregarlo a la lista"
				});
			}
			
			if (!front_id_banco) {
				throw Object({
					status: false,
					mensaje: "Selecciona un banco para agregarlo a la lista"
				});
			}
			
			if (!front_cantidad_empleado || front_cantidad_empleado <= 0) {
				throw Object({
					status: false,
					mensaje: "La cantidad de empleados no es valida."
				});
			}
			
			let cant = 0;
			let cant_banco = Number(front_cantidad_empleado);
			for (let i = 0; i < this.state.ficha_tecnica_cantidad_empleado_esquema.length; i++) {
				if (Number(this.state.ficha_tecnica_cantidad_empleado_esquema[i].id_cat_esquema) === Number(front_id_esquema_banco)) {
					cant = Number(this.state.ficha_tecnica_cantidad_empleado_esquema[i].cantidad_empleado_esquema);
				}
			}
			for (let i = 0; i < this.state.ficha_tecnica_cantidad_empleado_banco.length; i++) {
				if (Number(this.state.ficha_tecnica_cantidad_empleado_banco[i].id_cat_esquema) === Number(front_id_esquema_banco)) {
					cant_banco = cant_banco + Number(this.state.ficha_tecnica_cantidad_empleado_banco[i].cantidad_empleado);
				}
			}
			console.log("CANTIDADES :::: ", cant_banco, cant, cant_banco > cant);
			if (cant_banco > cant) {
				throw Object({
					status: false,
					mensaje: "La cantidad de empleados no puede exceder del limite registrado como esquema."
				});
			}
			
			for (let i = 0; i < this.state.lista_esquemas.length; i++) {
				if (Number(this.state.lista_esquemas[i].id_cat_esquema) === Number(front_id_esquema_banco)) {
					front_nombre_esquema = this.state.lista_esquemas[i].abreviatura;
				}
			}
			
			for (let i = 0; i < this.state.lista_bancos.length; i++) {
				if (Number(this.state.lista_bancos[i].id_cat_banco) === Number(front_id_banco)) {
					front_nombre_banco = this.state.lista_bancos[i].banco;
				}
			}
			
			arr_temp.push({
				id_cat_esquema: front_id_esquema_banco,
				esquema: front_nombre_esquema,
				id_cat_banco: front_id_banco,
				banco: front_nombre_banco,
				cantidad_empleado: front_cantidad_empleado
			});
			
			this.setState({
				ficha_tecnica_cantidad_empleado_banco: arr_temp,
				front_id_esquema_banco: "",
				front_nombre_banco: "",
				front_id_banco: "",
				front_cantidad_empleado: ""
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	agregar_ficha_tecnica_cantidad_empleado_esquema = () => {
		
		try {
			
			let arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_esquema);
			let front_nombre_esquema = this.state.front_nombre_esquema;
			let front_id_esquema = this.state.front_id_esquema;
			let front_cantidad_empleado = this.state.front_cantidad_empleado_esquema;
			
			
			if (!front_id_esquema) {
				throw Object({
					status: false,
					mensaje: "Selecciona un esquema para agregarlo a la lista"
				});
			}
			
			if (!front_cantidad_empleado) {
				throw Object({
					status: false,
					mensaje: "Escribe la cantidad de empleados para agregarlo a la lista"
				});
			}
			
			for (let i = 0; i < this.state.lista_esquemas.length; i++) {
				if (Number(this.state.lista_esquemas[i].id_cat_esquema) === Number(front_id_esquema)) {
					front_nombre_esquema = this.state.lista_esquemas[i].abreviatura;
				}
			}
			
			arr_temp.push({
				id_cat_esquema: front_id_esquema,
				esquema: front_nombre_esquema,
				cantidad_empleado_esquema: front_cantidad_empleado
			});
			
			this.setState({
				ficha_tecnica_cantidad_empleado_esquema: arr_temp,
				front_nombre_esquema: "",
				front_id_esquema: "",
				front_cantidad_empleado_esquema: ""
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
		
	};
	
	eliminar_ficha_tecnica_cantidad_empleado_banco = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_banco);
		arr_temp.splice(key, 1);
		this.setState({
			ficha_tecnica_cantidad_empleado_banco: arr_temp
		});
	};
	
	eliminar_ficha_tecnica_cantidad_empleado_esquema = (key) => {
		var arr_temp = FieldsJs.Copy(this.state.ficha_tecnica_cantidad_empleado_esquema);
		arr_temp.splice(key, 1);
		this.setState({
			ficha_tecnica_cantidad_empleado_esquema: arr_temp
		});
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_ahorro}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Documento'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={2}>
									
									{this.state.lista_documento.map((item, key) => (
										<Grid key={key} item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
											<ArchivosExpedientes
												index={key}
												variable={'documento'}
												texto={item.documento}
												id_ahorro={item.id_ahorro || ""}
												id_cat_documento={item.id_cat_documento || ""}
												item={{
													loading: !!item.loading,
													base64: item.base64,
													formato: item.formato,
													archivo: item.archivo,
													is_required: item.is_required,
												}}
												fileSelect={this.fileSelect}
												getBase64={this.getBase64}
												disabledSeleccionarCambiarArchivo={false}
											/>
										</Grid>
									))}
								
								</Grid>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Fragment>
							<Button onClick={this.close} color="primary">
								{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
							</Button>
						</Fragment>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalAhorro.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalAhorro;
