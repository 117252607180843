import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const GrupoService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				id_cat_grupo: filtro.id_cat_grupo || null,
				nombre: filtro.nombre || null,
				municipio: filtro.municipio || null,
				localidad: filtro.localidad || null,
				capacidad: filtro.capacidad || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Grupo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: (filtro) => {
		let params = {
			filtro: {
				id_cat_grupo: filtro.id_cat_grupo || null,
				nombre: filtro.nombre || null,
				municipio: filtro.municipio || null,
				localidad: filtro.localidad || null,
				capacidad: filtro.capacidad || null,
				activo: 1,
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Grupo_Datos_Todo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_grupo: form.id_grupo || '',
			id_cat_grupo: form.id_cat_grupo || '',
			nombre: form.nombre || '',
			municipio: form.municipio || '',
			localidad: form.localidad || '',
			capacidad: form.capacidad || '',
			activo: (Number(form.activo) === 1)
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Grupo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_grupo: form.id_grupo || '',
			id_cat_grupo: form.id_cat_grupo || '',
			nombre: form.nombre || '',
			municipio: form.municipio || '',
			localidad: form.localidad || '',
			capacidad: form.capacidad || '',
			activo: (Number(form.activo) === 1)
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Grupo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_grupo) => {
		let params = {
			id_grupo: id_grupo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Grupo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
