import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const AlbumService = {
	Listar: (filtro, paginacion) => {
		let fecha = filtro.fecha ? DateFormat.FormatSql(filtro.fecha) : null;
		let params = {
			filtro: {
				fecha: fecha || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Album_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Album_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_album: null,
			codigo: form.codigo || null,
			id_cat_grupo: form.id_cat_grupo || null,
			archivo: form.archivo || null,
			archivo_formato: form.archivo_formato || null,
			archivo_nombre: form.archivo_nombre || null,
			nombre: form.nombre || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Album_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_album: form.id_album || null,
			codigo: form.codigo || null,
			id_cat_grupo: form.id_cat_grupo || null,
			archivo: form.archivo || null,
			archivo_formato: form.archivo_formato || null,
			archivo_nombre: form.archivo_nombre || null,
			nombre: form.nombre || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Album_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_album) => {
		let params = {
			id_album: id_album
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Album_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
