import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {HdrWeakOutlined, PersonOutlined, SearchOutlined, TurnedInNotOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Input from "@material-ui/core/Input";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class FiltrosIntereses extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			cliente: '',
			id_cat_grupo: '',
			numero_asistentes: '',
			fecha: null,
			activo: '',
			lista_grupo: []
		};
		
		this.ListGrupo();
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_cat_grupo = this.state.id_cat_grupo || undefined;
		filtro.monto = this.state.monto || undefined;
		filtro.fecha = this.state.fecha || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	
	ListGrupo = () => {
		return new Promise((resolve, reject) => {
			CatService.ListGrupo().then((response) => {
				this.setState({
					lista_grupo: response.data
				});
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={4} lg={4} xl={4}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrWeakOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth required>
									<InputLabel>Grupo</InputLabel>
									<Select
										native
										value={this.state.id_cat_grupo}
										onChange={(e) => {
											this.handleChange(e);
										}}
										name="id_cat_grupo"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.state.lista_grupo.map(option => (
											<option key={option.id_cat_grupo}
											        value={option.id_cat_grupo}>
												{option.cat_grupo}
											</option>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<TurnedInNotOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="monto"
									onChange={this.handleChange}
									inputProps={{maxLength: 10}}
									onKeyPress={EnteroSolo}
									label="Monto"
									autoComplete={'off'}
									value={this.state.monto}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<KeyboardDatePicker
							format={'dd/MM/yyyy'}
							fullWidth
							clearable
							inputProps={{readOnly: true}}
							KeyboardButtonProps={{
								'aria-label': 'Fecha',
							}}
							label="Fecha"
							value={this.state.fecha}
							onChange={(date) => {
								this.handleChange(null, null, null, date, 'fecha');
							}}
							// minDate={moment(new Date())}
							clearLabel={'Limpiar'}
							okLabel={'Aceptar'}
							cancelLabel={'Cancelar'}
							onError={console.log}
							disabled={this.props.tipo === 'view'}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosIntereses.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosIntereses;
