import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const TallerService = {
	Listar: (filtro, paginacion) => {
		let fecha = filtro.fecha ? DateFormat.FormatSql(filtro.fecha) : null;
		let params = {
			filtro: {
				nombre: filtro.nombre || null,
				numero_asistentes: filtro.numero_asistentes || null,
				fecha: fecha || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Taller_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Taller_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_taller: null,
			codigo: form.codigo || null,
			nombre: form.nombre || null,
			numero_asistentes: form.numero_asistentes || null,
			compromisos: form.compromisos || null,
			material: form.material || null,
			id_cat_grupo: form.id_cat_grupo || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0,
			archivo: form.archivo || null,
			archivo_formato: form.archivo_formato || null,
			archivo_nombre: form.archivo_nombre || null,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Taller_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let params = {
			id_taller: form.id_taller || null,
			codigo: form.codigo || null,
			nombre: form.nombre || null,
			numero_asistentes: form.numero_asistentes || null,
			compromisos: form.compromisos || null,
			material: form.material || null,
			id_cat_grupo: form.id_cat_grupo || null,
			fecha: fecha || null,
			activo: form.activo ? 1 : 0,
			archivo: form.archivo || null,
			archivo_formato: form.archivo_formato || null,
			archivo_nombre: form.archivo_nombre || null,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Taller_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_taller) => {
		let params = {
			id_taller: id_taller
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Taller_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
