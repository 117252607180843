import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {AlbumService} from "../../../../services/AlbumService/AlbumService";
import ModalAlbum from "./ModalAlbum";
import {CONFIG} from "../../../../settings/Config/Config";
import Grid from "@material-ui/core/Grid";
import image_vacio from "../../../../assets/img/icons/image.svg";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la imagen del album?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				AlbumService.Eliminar(item.id_album).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Grid container spacing={2}>
				{lista.map((item, key) => (
					<Grid item className={'text-left'} xs={3} sm={3} md={3} lg={3} xl={3} alignItems={'center'}>
						<Fragment>
							<Paper style={{overflowX: "auto"}}>
								<div style={{fontSize: 14, margin: 5, fontWeight: 500, width: '96%', textAlign: 'center'}}>
									{item.nombre}
								</div>
								<div style={{fontSize: 11, margin: 5, textAlign: 'center'}}>
									{item.grupo ? 'Grupo: ' + item.grupo :''}
								</div>
								<div style={{fontSize: 11, margin: 5, textAlign: 'center'}}>
									{DateFormat.FormatText(item.fecha)}
								</div>
								<div align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'vertical-inline text-center'}>
										<div className={'v-center'}>
											<DivSvg img={item.archivo ? CONFIG.src + item.archivo : image_vacio} height={120} width={80}/>
										</div>
										<div className={'v-center margin-10-L'}>
											<DivSvg img={item.archivodos ? CONFIG.src + item.archivodos : image_vacio} height={120} width={80}/>
										</div>
										<div className={'v-center margin-10-L'}>
											<DivSvg img={item.archivotres ? CONFIG.src + item.archivotres : image_vacio} height={120} width={80}/>
										</div>
									</div>
								</div>
								<div align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalAlbum
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalAlbum
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</div>
							</Paper>
						</Fragment>
					</Grid>
				))}
			</Grid>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
