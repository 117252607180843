import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const PrestamoService = {
	Listar: (filtro, paginacion) => {
		let fecha = filtro.fecha ? DateFormat.FormatSql(filtro.fecha) : null;
		let params = {
			filtro: {
				nombre: filtro.nombre || null,
				id_cat_grupo: filtro.id_cat_grupo || null,
				fecha: fecha || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Prestamo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Prestamo_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let fecha_vencimiento = (form.fecha_vencimiento || null) ? DateFormat.FormatSql(form.fecha_vencimiento) : null;
		let params = {
			id_prestamo: null,
			codigo: form.codigo || null,
			nombre: form.nombre || null,
			id_cat_grupo: form.id_cat_grupo || null,
			id_vigente:  form.id_vigente || null,
			id_cat_sexo: form.id_cat_sexo || null,
			id_cat_municipio: form.id_cat_municipio || null,
			id_cat_estado: form.id_cat_estado || null,
			id_cat_localidad: form.id_cat_localidad || null,
			edad: form.edad || null,
			intereses: form.intereses || null,
			observaciones: form.observaciones || null,
			monto: form.monto || null,
			fecha: fecha,
			fecha_vencimiento: fecha_vencimiento,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Prestamo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha = (form.fecha || null) ? DateFormat.FormatSql(form.fecha) : null;
		let fecha_vencimiento = (form.fecha_vencimiento || null) ? DateFormat.FormatSql(form.fecha_vencimiento) : null;
		let params = {
			id_prestamo: form.id_prestamo || null,
			codigo: form.codigo || null,
			nombre: form.nombre || null,
			id_cat_grupo: form.id_cat_grupo || null,
			id_vigente:  form.id_vigente || null,
			id_cat_sexo: form.id_cat_sexo || null,
			id_cat_municipio: form.id_cat_municipio || null,
			id_cat_estado: form.id_cat_estado || null,
			id_cat_localidad: form.id_cat_localidad || null,
			edad: form.edad || null,
			intereses: form.intereses || null,
			observaciones: form.observaciones || null,
			monto: form.monto || null,
			fecha: fecha,
			fecha_vencimiento: fecha_vencimiento,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Prestamo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_prestamo) => {
		let params = {
			id_prestamo: id_prestamo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Prestamo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
